@tailwind base;
@tailwind components;
@tailwind utilities;


.appBackground{
  background-image: url(./img/bg.jpg);
  
}

.show{
  display: block;
}

.hidden{
  display: none;
}

.active{
  background-color: antiquewhite;
}

html, body{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {

  font-family: 'Lato', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@font-face{
  font-family: NovaCut;
  src: url(../public/NovaCut-Regular.ttf);
}

.NovaCut{
  font-family: NovaCut;
}
